import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

import heroBg from "../images/home/hero_bg.svg"

// Stories
import badge from "../images/OklahomaStandard-Badge-Blue.svg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TakeAction from "../components/take-action-component"
import SlideShow from "../components/slideshow"
import ParallaxImageMove from "../components/parallax-image-move"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Oklahoma Standard - Teaching Oklahomans the Values of Service, Honor, & Kindness" description="Discover tools and resources for businesses, schools, and communities to preserve the Oklahoma Standard and teach future generations of Oklahomans the values of Service, Honor, and Kindness." />
    <div class="banner columns has-background-link has-text-white">
      <div class="column pa4" data-sal="slide-right" data-sal-delay="100" data-sal-duration="800">
        <h1 class="is-uppercase has-text-weight-black is-size-xl" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.hero_heading }} />
        <p class="mv3 is-size-3 has-text-weight-semi-bold pr4" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.hero_body }} />
        <Link to="takeaction/at-work" className="button linkish outline marg">
          Take Action
        </Link>
      </div>
      <div class="column is-half-tablet pa0 is-half-desktop ieflexfix is-relative onmobheight">

        <SlideShow />
      </div>
    </div>

    <div class="container is-fullhd">
      <div class="columns pt4">
        
        <div class="column ph4 pv4 ieflexfix mainstandtop" data-sal="fade" data-sal-easing="ease-in-elastic" data-sal-duration="800">
          <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
          <p class="pt3">
            <Link to="about" className="learn-more">
              Learn More
            </Link>
            <Link to="https://issuu.com/memorialmuseum/docs/oklahomastandard-magazine" className="outline-blue">
              Learn More
            </Link>
          </p>
        </div>
        { data.wordpressPage.acf.body_img.localFile &&
          <div class="column is-narrow timewid">
            <figure class="image">
              <ParallaxImageMove imgData={data.wordpressPage.acf.body_img.localFile.childImageSharp.fluid}/>
            </figure>
          </div>
        }
      </div>
    </div>
 
    { data.wordpressPage.acf.medium_text_callout &&
      <section class="hero is-medium is-link step-up"
          style={{ 
            backgroundImage: `url(${heroBg})`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `top center`
          }}
      >
        <div class="hero-body">
          <div class="container has-text-centered" data-sal="slide-up" data-sal-duration="800">
            <h1 class="title is-size-1 is-uppercase has-text-weight-black" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.medium_text_callout }} />
            <Link to="takeaction/at-work" className="button is-outlined is-white mb0">
              Take Action
            </Link>
          </div>
        </div>
      </section>
    }
    
    <div class="columns is-vcentered has-text-centered pt5 pb4">
      <div class="column column-pad">
        <h2 class="has-text-primary is-uppercase has-text-weight-black is-size-1" 
          data-sal="fade-up"
          data-sal-delay="100"
          data-sal-easing="ease-in-cubic"
          data-sal-duration="800"
        >
            Show up to Serve.
        </h2>
        <h2 class="has-text-primary is-uppercase has-text-weight-black is-size-1" 
          data-sal="fade-up"
          data-sal-delay="200"
          data-sal-easing="ease-in-back"
          data-sal-duration="800"
        >
          Rise up to honor.
          </h2>
        <h2 class="has-text-primary is-uppercase has-text-weight-black is-size-1" 
          data-sal="fade-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-back"
          data-sal-duration="800"
        >
          Step up to be kind.
        </h2>
      </div>
    </div>
    
    <TakeAction />

    <section class="stories">
      <div class="columns column-pad pv5">
        <div class="column setoff13 is-4" data-sal="fade" data-sal-duration="800">
          <h2 class="is-size-xl has-text-primary has-text-weight-black is-uppercase alignit">
            Together we will Elevate our state.
          </h2>
        </div>
        <div class="column is-4 is-offset-1 gray-badge" data-sal="fade" data-sal-duration="800">
          <img src={badge} alt=""/>
        </div>
      </div>
    </section>
   
  </Layout>
)

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(height: 110) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const query = graphql`
  {
    wordpressPage(slug: {eq: "index"}) {
      id
      content
      slug
      title
      acf {
        hero_heading
        hero_body
        medium_text_callout
        body_img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
export default IndexPage
