import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const SlideShow = () => {
  const [index, setIndex] = useState(0)

  const query = useStaticQuery(
    graphql`
    {
      allWordpressAcfPages(filter: {wordpress_id: { eq: 1329 }}) {
        edges {
          node {
            acf {
              hero_image_1 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }            
                  }
                }
              }

              hero_image_2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }            
                  }
                }
              }

              hero_image_3 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }            
                  }
                }
              }

            }
          }
        }
      }
    }
    `
  )

  const data = query.allWordpressAcfPages.edges;
  const allFile = Object.values(data[0].node.acf);

  //Minus 1 for array offset from 0
  const length = allFile.length - 1
  
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
    
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)
  
  const node = allFile[index]

  return (
    <div class="slidediv">
      <div class="slidediv">
        <Img class="slideimg"
          fluid={node.localFile.childImageSharp.fluid}
          key={node.id}
          alt=""
        />
      </div>
      <div class="slidebutton">
        <button class="slidebuttonpre" onClick={() => handlePrevious()}>&#8249;</button>
        <button class="slidebuttonnex" onClick={() => handleNext()}>&#8250;</button>
      </div>
    </div>
  )
}

export default SlideShow